/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable arrow-body-style */
// Librerias
import React, { useEffect, useState } from 'react';
import { VscChromeClose } from 'react-icons/vsc';

// Contextos

// Hooks

// Componentes
import api from '../api/index';

// Imagenes

// Estilos

/**
 * Descripción: La descripción de por qué estamos haciendo este componente y que resuelve.
 *
 * Implementación: Descripción sobre cómo puedes implementar el componente.
 *
 * Bugs: Qué bugs se han presentado y como se solucionan.
 */

const Popup = () => {
  // Estados
  const [show, setShow] = useState(false);
  const [data, setData] = useState({
    loading: false,
    data: {
      status: '',
      message: '',
      result: [],
    },
    error: null,
  });

  // Contextos

  // Hooks

  // Funciones
  const getData = async () => {
    setData({
      ...data,
      loading: true,
    });
    try {
      const response = await api.popup.get();
      setData({
        loading: false,
        data: response,
      });
      setShow(true);
    } catch (error) {
      setData({
        loading: false,
        error,
      });
    }
  };

  const handleClose = (e) => {
    e.stopPropagation();
    e.target.nodeName !== 'IMG' && setShow(false);
  };

  // UseEffects
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {show && data.data.result ? (
        <div className='popup' onClick={handleClose}>
          <div className='popup__container'>
            <div className='popup__header'>
              <button
                className='popup__header__close'
                title='cerrar/close'
                onClick={handleClose}
              >
                <VscChromeClose />
              </button>
            </div>
            {data.data?.result?.slice(0, 1)?.map((item) => (
              <a
                key={item.id}
                href={item.pdf ? item.pdf : item.url}
                target='_blank'
                rel='noopener noreferrer'
                className='popup__image'
              >
                <picture>
                  <source srcSet={item.imagenDesktop} media='(min-width: 992px)' />
                  <source srcSet={item.imagenMobile} media='(max-width: 991px)' />
                  <img src={item.imagenDesktop} alt='popup' />
                </picture>
              </a>
            ))}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Popup;
